@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");

@layer base {
  body {
    @apply font-[Raleway];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(
    to top,
    rgba(0, 11, 24, 1) 0%,
    rgba(0, 23, 45, 1) 20%,
    rgba(0, 38, 77, 1) 50%,
    rgba(2, 56, 110, 1) 65%,
    rgba(0, 73, 141, 1) 85%,
    rgba(0, 82, 162, 1) 100%
  ) !important;
}
